import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import SideModal from "../SideModal/SideModal";
import { states } from "../../utilis/states.utils";
import { BookingService } from "../../services/booking.service";
import { AdminService } from "../../services/admin.service";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ICreateManualInvoice } from "../../interfaces/razorpay.interface";
import { FaExclamationTriangle } from "react-icons/fa";

interface ICreateInvoiceModal {
  show: boolean;
  setShow: any;
}

export default function CreateMusicInvoice(props: ICreateInvoiceModal) {
  const [paidEnrollments, setPaidEnrollment] = useState<any>([]);
  const [enrollment, setEnrollment] = useState<any>({});
  const [finalState, setFinalState] = useState({
    dhunguruDiscount: 0,
    totalSession: 0,
    pricePerClass: 0,
    transactionId: "",
    extraClasses: 0,
  });

  const [state, setState] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<any>(new Date());

  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (
      !state ||
      !state.value ||
      !enrollment.value ||
      !finalState.dhunguruDiscount ||
      !finalState.totalSession ||
      !finalState.pricePerClass ||
      !finalState.transactionId ||
      !finalState.extraClasses
    ) {
      toast.error("All Fields are required!");
      return false;
    }
    setLoading(true);
    const inputPaymentData: ICreateManualInvoice = {
      enrollmentId: enrollment.value,
      dhunguruDiscount: Number(finalState.dhunguruDiscount),
      totalSession: Number(finalState.totalSession),
      pricePerClass: Number(finalState.pricePerClass),
      transactionId: finalState.transactionId,
      extraClasses: Number(finalState.extraClasses),
      state: state.value,
      invoice_date: moment(selectedDate).toISOString(),
    };

    await AdminService.createMusicManualInvoice(inputPaymentData)
      .then((res) => {
        if (res.status === 200) {
          props.setShow(false);
          setFinalState({
            dhunguruDiscount: 0,
            totalSession: 0,
            pricePerClass: 0,
            transactionId: "",
            extraClasses: 0,
          });
          toast.success("Success: Invoice Created!");
        }
      })
      .then((e) => {
        setLoading(false);
        console.error(e);
      });

    setLoading(false);
  };

  const getAllPaidSessions = async () => {
    await BookingService.getAllPaidMusicEnrollments().then((res) => {
      if (res.status === 200) {
        const select_data = res?.data?.data?.map((data: any) => {
          return {
            label:
              data.student_name +
              " - " +
              data.instructor_name +
              " - " +
              data.instrument +
              " - " +
              data.enrollmentId,
            value: data._id,
          };
        });
        setPaidEnrollment(select_data);
      }
    });
  };

  const handleChange = (e: any) => {
    setFinalState({ ...finalState, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getAllPaidSessions();
  }, []);

  const getGstPrice = () => {
    if (finalState.totalSession && finalState.pricePerClass) {
      const gst_total = getPriceAfterDhunguruDiscount() * 0.18;
      return gst_total;
    } else {
      return 0;
    }
  };

  const getFinalPrice = () => {
    if (finalState.totalSession && finalState.pricePerClass) {
      const after_gst = getPriceAfterDhunguruDiscount() + getGstPrice();

      return after_gst.toFixed(2);
    } else {
      return 0;
    }
  };

  const getPriceAfterDhunguruDiscount = () => {
    if (finalState.totalSession && finalState.pricePerClass) {
      const total_temp =
        Number(finalState.pricePerClass) * Number(finalState.totalSession);
      const dhunguru_after_discount =
        total_temp - Number(finalState.dhunguruDiscount);
      return dhunguru_after_discount;
    } else {
      return 0;
    }
  };

  return (
    <SideModal
      title={"Create MT Invoice Manually India"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Enrollment
            </Form.Label>
            <Select
              options={paidEnrollments}
              value={enrollment}
              isSearchable={true}
              placeholder="Select Enrollments"
              onChange={(e: any) => {
                setEnrollment(e);
              }}
              isClearable
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Dhunguru Discount
            </Form.Label>
            <Form.Control
              type="number"
              name="dhunguruDiscount"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Total Sessions
            </Form.Label>
            <Form.Control
              type="number"
              name="totalSession"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Price Per Class
            </Form.Label>
            <Form.Control
              type="number"
              name="pricePerClass"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Extra Classes
            </Form.Label>
            <Form.Control
              type="number"
              name="extraClasses"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Transaction Id
            </Form.Label>
            <Form.Control
              type="text"
              name="transactionId"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              State(Mandatory)
            </Form.Label>
            <Select
              options={states.map((state) => {
                return {
                  label: state,
                  value: state,
                };
              })}
              value={state}
              onChange={setState}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Invoice Date
            </Form.Label>
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              className="form-control"
              dateFormat="d-MM-yyyy"
            />
          </Form.Group>

          <div
            className="bg-muted rounded-3 mt-3 p-3 fs-12"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <div className="fw-bold text-secondary">
              <div>
                Price per Class:{" "}
                <span className="text-black">{finalState.pricePerClass}</span>
              </div>

              <div>
                {" "}
                Total Classes:{" "}
                <span className="text-black"> x {finalState.totalSession}</span>
              </div>
              <div>
                {" "}
                Extra Classes:{" "}
                <span className="text-black"> {finalState.extraClasses}</span>
              </div>
              <div>
                {" "}
                Total Price:{" "}
                <span className="text-success">
                  {" "}
                  = {finalState.pricePerClass * finalState.totalSession}
                </span>
              </div>
              <div>
                {" "}
                Dhunguru Discount:{" "}
                <span className="text-danger">
                  {" "}
                  - {finalState.dhunguruDiscount}
                </span>
              </div>
              <div>
                {" "}
                Total Price:{" "}
                <span className="text-black">
                  {" "}
                  {getPriceAfterDhunguruDiscount() > 0
                    ? getPriceAfterDhunguruDiscount()
                    : " --- All Fields Are Required --- "}
                </span>
              </div>

              <div>
                {state.label == "Haryana" ? (
                  <>
                    <div>----------- GST -----------</div>
                    <div>
                      CGST:{" "}
                      <span className="text-success">
                        ₹{getPriceAfterDhunguruDiscount() * 0.09}
                      </span>
                    </div>
                    <div>
                      SGST:{" "}
                      <span className="text-success">
                        ₹{getPriceAfterDhunguruDiscount() * 0.09}
                      </span>
                    </div>
                    <div>
                      Total GST:{" "}
                      <span className="text-success">
                        ₹
                        {getPriceAfterDhunguruDiscount() * 0.09 +
                          getPriceAfterDhunguruDiscount() * 0.09}
                      </span>
                      <div>----------------------------</div>
                    </div>
                  </>
                ) : (
                  <>
                    GST:{" "}
                    <span className="text-success">
                      {" "}
                      {state.label && getGstPrice() > 0 ? (
                        <>+{getGstPrice()}</>
                      ) : (
                        " --- All Fields Are Required --- "
                      )}
                    </span>
                  </>
                )}{" "}
              </div>
              <div>
                {" "}
                Total Price with GST:{" "}
                <span className="text-primary">
                  {" "}
                  {Number(getFinalPrice()) > 0
                    ? getFinalPrice()
                    : " --- All Fields Are Required --- "}
                </span>
              </div>
            </div>
          </div>

          <Button
            className="mt-4"
            onClick={onSubmit}
            disabled={loading}
            variant="danger"
          >
            Create GST Invoice <FaExclamationTriangle />
          </Button>
        </>
      }
    />
  );
}
