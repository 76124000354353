import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Invoices from "./EnrollmentInvoices.index";
import InvoicesInternational from "./EnrollmentInvoicesInternational.index";
import MusicTherapy from "./MusicTherapy";

function EnrollmentInvoiceTab() {
  return (
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3 p-2  fw-bold ml-5 "
    >
      <Tab
        eventKey="home"
        title={
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>India</div>
        }
      >
        <Invoices />
      </Tab>
      <Tab
        eventKey="profile"
        title={
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            International
          </div>
        }
      >
        <InvoicesInternational />
      </Tab>
      <Tab
        eventKey="musictherapy"
        title={
          <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            Music Therapy
          </div>
        }
      >
        <MusicTherapy />
      </Tab>{" "}
    </Tabs>
  );
}

export default EnrollmentInvoiceTab;
