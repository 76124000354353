import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import CardBasic from "../../../components/Card/card.basic";
import ReactDatePicker from "react-datepicker";
import InvoicesTable from "../../../components/Table/EnrollmentInvoicesTable";
import { ExportToCsv } from "export-to-csv-fix-source-map";
import InvoiceService from "../../../services/invoice.service";
import toast from "react-hot-toast";
import CreateInvoiceModal from "../../../components/Modals/CreateInvoice.modal";
import { CSVDownload, CSVLink } from "react-csv";
import CreatePartialInvoiceModal from "../../../components/Modals/CreatePartialInvoice.modal";

interface IOptions {
  fieldSeparator: any;
  quoteStrings: any;
  decimalSeparator: any;
  showLabels: any;
  showTitle: any;
  title: any;
  useTextFile: any;
  useBom: boolean;
  useKeysAsHeaders: boolean;
}

const Invoices = () => {
  const [data, setData] = useState<any>([]);
  const [cancelledData, setCancelledData] = useState<any>([]);
  const [invoice, setInvoice] = useState<any>([]);
  const [id, setId] = useState<any>();
  const [dateRange, setDateRange] = useState({
    startDate: moment(new Date()).subtract("10", "days").toDate(),
    endDate: moment(new Date()).add("30", "days").toDate(),
  });
  const [isBulkDownload, setIsBulkDownload] = useState<boolean>(false);
  const [manualInvoiceShow, setManualInvoiceShow] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>({
    fromInvoiceNumber: "",
    toInvoiceNumber: "",
  });

  const [partialInvoice, setPartialInvoice] = useState<boolean>(false);
  // const [downloadCsvFormat, setDownloadCsvFormat] = useState([]);

  let csvOptions: IOptions = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "CSV Data",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const handleInvoiceDataChange = (e: any) => {
    setInvoiceData({ ...invoiceData, [e.target.name]: e.target.value });
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const getAllInvoices = async () => {
    const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");

    if (!fromDate || !toDate) {
      return false;
    }
    await InvoiceService.getAllInvoices(
      fromDate,
      toDate,
      invoiceData.fromInvoiceNumber,
      invoiceData.toInvoiceNumber
    )
      .then((res) => {
        if (res.status === 200) {
          setData(res?.data?.data);
          // createDownloadCSVFormat(res?.data?.data);
          if (res?.data?.data?.length === 0) {
            throw Error;
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const getCancelledInvoices = async () => {
    const fromDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const toDate = moment(dateRange.endDate).format("YYYY-MM-DD");

    if (!fromDate || !toDate) {
      return false;
    }
    await InvoiceService.getCancelledIndiaInvoices(
      fromDate,
      toDate,
      invoiceData.fromInvoiceNumber,
      invoiceData.toInvoiceNumber
    )
      .then((res) => {
        if (res.status === 200) {
          setCancelledData(res?.data?.data);
          // createDownloadCSVFormat(res?.data?.data);
          if (res?.data?.data?.length === 0) {
            throw Error;
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const statusData = (data: any) => {
    const allStatusData = data?.enrollmentId?.payment_details?.map(
      (paymentDetail: any) => paymentDetail.status
    );
    const uniqueStatus: any = Array.from(new Set(allStatusData)).join(",");
    return uniqueStatus;
  };

  const csvFinalData = data?.map((data: any) => {
    return {
      Invoice_number: data?.invoiceNumber,
      Subject: data?.courseId?.instrument,
      Description: data?.items
        ?.map?.((data: any) => {
          return data?.description;
        })
        .toString(),
      Ordered_By: data?.userAddress?.name,
      date_of_purchase: data?.dateOfPurchase,
      SGST: data?.gst?.sgst?.toFixed(2),
      CGST: data?.gst?.cgst?.toFixed(2),
      IGST: data?.gst?.igst?.toFixed(2),
      Discount: data?.discount?.toFixed(2),
      Final_Price: data?.totalInvoicePrice?.toFixed(2),
      "Invoice URL": data.invoiceUrl,
      "Payment Gateway (if any)": data?.razorPayPaymentId
        ? data?.razorPayPaymentId?.gateway
        : "--",
      "Payment Id":
        data.razorPayPaymentId && data.razorPayPaymentId.paymentSuccessObject
          ? data.razorPayPaymentId?.gateway == "rzp"
            ? data.razorPayPaymentId.paymentSuccessObject?.payload?.order
                ?.entity?.id
            : data.razorPayPaymentId?.gateway == "cashfree"
            ? data.razorPayPaymentId?.paymentSuccessObject?.data?.order
                ?.order_id
            : data.razorPayPaymentId._id
          : "--",
      "Student Email": data?.studentId && data?.studentId?.email,
      Status: statusData(data),
      "UTM Source": data?.studentId?.onboarding?.utms?.utm_source,
      "UTM Campaign": data?.studentId?.onboarding?.utms?.utm_campaign,
      "UTM Medium": data?.studentId?.onboarding?.utms?.utm_medium,
      "UTM Content": data?.studentId?.onboarding?.utms?.utm_content,
    };
  });
  const csvDeleteInvoiceData = cancelledData?.map((data: any) => {
    return {
      Invoice_number: data?.invoiceNumber,
      Subject: data?.courseId?.instrument,
      Description: data?.items
        ?.map?.((data: any) => {
          return data?.description;
        })
        .toString(),
      Ordered_By: data?.userAddress?.name,
      date_of_purchase: data?.dateOfPurchase,
      SGST: data?.gst?.sgst?.toFixed(2),
      CGST: data?.gst?.cgst?.toFixed(2),
      IGST: data?.gst?.igst?.toFixed(2),
      Discount: data?.discount?.toFixed(2),
      Final_Price: data?.totalInvoicePrice?.toFixed(2),
      "Invoice URL": data.invoiceUrl,
      "Payment Gateway (if any)": data?.razorPayPaymentId
        ? data?.razorPayPaymentId?.gateway
        : "--",
      "Payment Id":
        data.razorPayPaymentId && data.razorPayPaymentId.paymentSuccessObject
          ? data.razorPayPaymentId?.gateway == "rzp"
            ? data.razorPayPaymentId.paymentSuccessObject?.payload?.order
                ?.entity?.id
            : data.razorPayPaymentId?.gateway == "cashfree"
            ? data.razorPayPaymentId?.paymentSuccessObject?.data?.order
                ?.order_id
            : data.razorPayPaymentId._id
          : "--",
      "Student Email": data?.studentId && data?.studentId?.email,
      Status: statusData(data),
      "UTM Source": data?.studentId?.onboarding?.utms?.utm_source,
      "UTM Campaign": data?.studentId?.onboarding?.utms?.utm_campaign,
      "UTM Medium": data?.studentId?.onboarding?.utms?.utm_medium,
      "UTM Content": data?.studentId?.onboarding?.utms?.utm_content,
    };
  });

  const handleDownload = async (id: any) => {
    await InvoiceService.getInvoicebyId({ id })
      .then((res: any) => {
        if (res.status === 200) {
          setInvoice(res?.data?.data);
          if (res?.data?.data?.length === 0) {
            throw Error;
          }
        }
      })
      .catch((err: any) => {
        toast.error("Unable to Downlaod Invoice");
      });
  };

  useEffect(() => {
    getAllInvoices();
    getCancelledInvoices();
    setIsBulkDownload(false);
  }, [dateRange, manualInvoiceShow, invoiceData]);

  useEffect(() => {
    if (invoice?.length > 1) {
      csvExporter.generateCsv(invoice);
    }
  }, [invoice]);

  const sgstSum: any = Number(
    data?.reduce((n: any, { gst }: any) => n + gst?.sgst, 0)
  ).toFixed(2);
  const cgstSum: any = Number(
    data?.reduce((n: any, { gst }: any) => n + gst?.cgst, 0).toFixed(2)
  );
  const igstSum: any = Number(
    data?.reduce((n: any, { gst }: any) => n + gst?.igst, 0)
  ).toFixed(2);
  const totalGst: any = Number(cgstSum) + Number(sgstSum) + Number(igstSum);
  const totalRevenue: any = data?.reduce(
    (rev: any, item: any) => Number(rev) + Number(item.totalInvoicePrice),
    0
  );
  const totalDiscount: any = data?.reduce(
    (rev: any, item: any) => Number(rev) + Number(item.discount),
    0
  );

  return (
    <>
      <Container>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <h6>Filter</h6>
                  <Row>
                    <Col md={3}>
                      <Form.Label className="fw-bold text-secondary fs-12">
                        Created At
                      </Form.Label>
                      <ReactDatePicker
                        onChange={(e: any) => {
                          setDateRange({
                            startDate: e[0],
                            endDate: e[1],
                          });
                        }}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        selectsRange
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Starting Invoice Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="fromInvoiceNumber"
                          onChange={handleInvoiceDataChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group>
                        <Form.Label className="fw-bold text-secondary fs-12">
                          Ending Invoice Number
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="toInvoiceNumber"
                          onChange={handleInvoiceDataChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col className="my-4">
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                onClick={() => {
                  setManualInvoiceShow(true);
                }}
                variant="danger"
                className="mx-2"
              >
                Create Manual Invoice
              </Button>
              <Button
                size="sm"
                onClick={() => {
                  setPartialInvoice(true);
                }}
                variant="secondary"
                className="mx-2"
              >
                Create Partial Invoice
              </Button>
              <CSVLink data={csvFinalData} filename={"Invoice.csv"}>
                <Button size="sm" className="mx-2">
                  Download CSV
                </Button>
              </CSVLink>
              <CSVLink data={csvDeleteInvoiceData} filename={"Invoice.csv"}>
                <Button size="sm" className="mx-2">
                  Cancelled CSV
                </Button>
              </CSVLink>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Card text={"dark"} className="mb-2">
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>
                  <h4 className="fw-bold text-secondary">Total Invoices</h4>
                  <div className="fw-bold text-secondary fs-12">
                    Count: <span className="text-black"> {data.length} </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card text={"dark"} className="mb-2">
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>
                  <h4 className="fw-bold text-secondary">GST</h4>
                  <div className="fw-bold text-secondary fs-12">
                    Total Collection:{" "}
                    <span className="text-black"> ₹{totalGst} </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card text={"dark"} className="mb-2">
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>
                  <h4 className="fw-bold text-secondary">Total Revenue</h4>
                  <div className="fw-bold text-secondary fs-12">
                    Total Collection:{" "}
                    <span className="text-black"> ₹{totalRevenue} </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card text={"dark"} className="mb-2">
              <Card.Body>
                <Card.Title></Card.Title>
                <Card.Text>
                  <h4 className="fw-bold text-secondary">Total Discount</h4>
                  <div className="fw-bold text-secondary fs-12">
                    Total Collection:{" "}
                    <span className="text-black"> ₹{totalDiscount} </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <CardBasic
              className="mt-3"
              body={
                <>
                  <InvoicesTable
                    tableData={data}
                    handleDownload={handleDownload}
                    reload={getAllInvoices}
                  />
                </>
              }
            />
          </Col>
        </Row>
      </Container>

      <CreateInvoiceModal
        setShow={setManualInvoiceShow}
        show={manualInvoiceShow}
      />

      <CreatePartialInvoiceModal
        setShow={setPartialInvoice}
        show={partialInvoice}
      />
    </>
  );
};

export default Invoices;
