import React, { useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { FaDownload, FaLink } from "react-icons/fa";
import { IoTrashBinSharp } from "react-icons/io5";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { AdminService } from "../../services/admin.service";
import CustomToggle from "../Menu/CustomMenu";
import ConfirmationModal from "../Modals/Confirmation.modal";
import DeleteConfirmation from "../Modals/DeleteConfirmation";
import { FontBold, NormalText } from "../Text/TableText.formats";
import "./table.css";

interface IInvoices {
  tableData: any;
  handleDownload: any;
  reload: any
}

const config = ["CGST", "SGST", "IGST"]


export default function InvoicesTable({ tableData, handleDownload, reload }: IInvoices) {


  const [showConfirmationModal, setShowConfirmationModal] = useState<any>(undefined);

  const handleDeleteEnrollmentInvoice = async () => {
    await AdminService.deleteEnrollmentInvoice(showConfirmationModal).then((res) => {
      if (res.status === 200) {
        toast.success("Invoice Deleted Successfully");
        setShowConfirmationModal(undefined);
        reload();
      }
    }).catch(err => {
      toast.error("Unable to delete this Invoice")
    })
  }

  const handleDownloadInvoice = (data: any) => {
    window.open(data, '_blank', 'noreferrer')
    // fetch(data).then(response => {
    //   response.blob().then(blob => {
    //     const fileURL = window.URL.createObjectURL(blob);
    //     let alink = document.createElement('a');
    //     alink.href = fileURL;
    //     alink.download = 'Invoice.pdf';
    //     alink.click();
    //   })
    // })
    // console.log(data, "data")
  }

  const statusData = (data: any) => {
    const allStatusData = data?.enrollmentId?.payment_details?.map((paymentDetail: any) => paymentDetail.status);
    const uniqueStatus: any = Array.from(new Set(allStatusData)).join(",");
    return uniqueStatus
  } 


  return (
    <>


      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Inv. No.</th>
            <th>Description</th>
            <th>Trans. Id</th>
            <th>Student Name</th>
            <th>CORD</th>
            <th>Status</th>
            <th>DOP</th>
            <th>Disc.</th>
            <th className="text-center" colSpan={config.length}>
              GST
            </th>
            <th>Final price</th>
            <th></th>
          </tr>
          <tr className="text-primary xcn-text-10">
            <th colSpan={8}></th>
            {config.map((data) => {
              return <th className="text-center">{data}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
              return (
                <tr>
                  <td>
                    <NormalText value={index + 1} />
                  </td>
                  <td>{data?.invoiceNumber}</td>
                  <td>
                    {data?.items?.map((user: any) => {
                      return user?.description;
                    })}
                  </td>
                  <td>{data.razorPayPaymentId && data.razorPayPaymentId.paymentSuccessObject ? data.razorPayPaymentId?.gateway == 'rzp' ?  data.razorPayPaymentId.paymentSuccessObject?.payload?.order?.entity?.id : data.razorPayPaymentId?.gateway == 'cashfree' ? data.razorPayPaymentId?.paymentSuccessObject?.data?.order?.order_id : data.razorPayPaymentId._id : "--"}</td>
                  <td>{data?.studentId?.firstName+ " " + data.studentId?.lastName}</td>
                  <td>{data.coordinator || "---"}</td>
                  <td> {statusData(data)}</td>
                  <td>{data?.dateOfPurchase}</td>
                  <td> ₹{data?.discount?.toFixed(2) || 0}</td>
                  {data?.gst && Object.values(data?.gst).map((items: any) => {
                    return (
                      <td className="text-secondary font-monospace">
                        ₹{items.toFixed(2)}
                      </td>
                    );
                  })}
                  <td> ₹{data?.totalInvoicePrice?.toFixed(2)}</td>
                  {/* <td>
                      <a href={data?.invoiceUrl ? data?.invoiceUrl : "#"}>
                        {" "}
                        <FaLink />
                      </a>
                    </td> */}
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdown-custom-components"
                      >
                        <BiDotsVertical className="cursor-pointer" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => handleDownloadInvoice(data && data.invoiceUrl)}
                        >
                          <FaLink className="text-primary" />{" "}
                          <span className="fw-bold text-primary fs-12">
                            Download
                          </span>
                          {/* </a> */}
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setShowConfirmationModal(data._id)
                          }}
                        >
                          <IoTrashBinSharp className="text-danger" />{" "}
                          <span className="fw-bold text-danger fs-12">
                            Cancel
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <ConfirmationModal
                      show={showConfirmationModal ? true : false}
                      setShow={() => setShowConfirmationModal(false)}
                      handleDelete={handleDeleteEnrollmentInvoice}
                    />
                  </td>
                </tr>
              );
            })
            : "No data found"}
        </tbody>
      </Table>
    </>
  );
}
