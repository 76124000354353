import * as React from "react";
import { useLocation, useNavigate } from "react-router";
import { baseUrl, privateConfig } from "../../../routes/config";
import "./style.css";
import { Image } from "react-bootstrap";
import dhunLogo from "../../../assets/logo.png";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RolesContext } from "../../../routes/private.context";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
console.log(privateConfig,'pvt')
const menu = privateConfig.filter((routes) => routes.show === true);
interface IRouter {
  path: string;
  show?: Boolean;
  element: any;
  name?: string;
  icon?: any;
  inActiveIcon?: any;
}
function SideDrawer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { roles, getUserRoles } = React.useContext(RolesContext);
  const checkIfRouteAllowed = (path: string) => {
    return roles.find((role: any) => role.role == path && role.allowed == true)
      ? true
      : false;
  };

  const selectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links active xcn-link-pointer activeClassTextColor d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  const notSelectedRoute = (index: number, item: IRouter) => {
    return (
      <div
        key={index}
        className="xcn-sidebar-links-inactive xcn-link-pointer d-flex align-items-center"
        onClick={() => navigate(baseUrl + "/" + item.path)}
      >
        {/* <FontAwesomeIcon icon={item?.icon} /> */}
        <item.icon />

        <div className="ms-2">{item.name}</div>
      </div>
    );
  };

  const handleLogout = () => {
    sessionStorage.removeItem("authKey");
    window.location.reload();
  };

  return (
    <>
      <Nav
        defaultActiveKey="0"
        className="flex-column xcn-dashboard-sub-nav flex-sm-column"
        style={{
          backgroundColor: "#0C0033",
          width: "14rem",
        }}
      >
        <div className="d-flex justify-content-center align-items-center mb-4">
          <div className="mt-4">
            <Image src={dhunLogo} height="30px" />
          </div>
        </div>

        {menu.map((data, index: any) => {
          if (data.show == true && checkIfRouteAllowed(data.role)) {
            return (
              <>
                {location.pathname.split("/")[2] === data.path
                  ? selectedRoute(index, data)
                  : notSelectedRoute(index, data)}
              </>
            );
          }
        })}

        <hr/>
        <div
          className="fw-bold text-center mt-4 text-white xcn-text-15 cursor-pointer"
          onClick={handleLogout}
        >
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faSignOutAlt} className="text-white" />
          </div>
          Logout
        </div>
      </Nav>
    </>
  );
}

export default SideDrawer;
