import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MusicTherapyInvoiceInternational from "./MusicTherapyInvoiceInternational";
import MusicTherapyInvoice from "./MusicTherapyInvoice";

function MusicTherapy() {
  return (
    <Tabs
      defaultActiveKey="india"
      id="uncontrolled-tab-example"
      className="mb-3 fw-bold ml-5 musictherapy"
      fill
      style={{ marginTop: "-10px", maxWidth: "200px" }}
    >
      <Tab eventKey="india" title="India" className="">
        <MusicTherapyInvoice />
      </Tab>{" "}
      <Tab
        eventKey="international"
        title="International "
        style={{ marginTop: "-25px" }}
      >
        <MusicTherapyInvoiceInternational />
      </Tab>{" "}
    </Tabs>
  );
}

export default MusicTherapy;
