import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import SideModal from "../SideModal/SideModal";
import { states } from "../../utilis/states.utils";
import { BookingService } from "../../services/booking.service";
import { AdminService } from "../../services/admin.service";
import { toast } from "react-hot-toast";
import DatePicker from "react-datepicker";
import moment from "moment";
import { ICreateManualInvoice } from "../../interfaces/razorpay.interface";
import { FaExclamationTriangle } from "react-icons/fa";
import StudentService from "../../services/student.service";
import InstructorService from "../../services/instructor.service";

interface ICreatePartialInvoiceModal {
  show: boolean;
  setShow: any;
}

export default function CreatePartialInvoiceModal(
  props: ICreatePartialInvoiceModal
) {


  const [finalState, setFinalState] = useState({
    description: "",
    totalAmount: 0,
    transactionId: "",
  })


  const [state, setState] = useState<any>({});
  const [selectedDate, setSelectedDate] = useState<any>(new Date());

  const [students, setStudents] = useState<any>([]);
  const [instructors, setInstructors] = useState<any>([]);

  const [selected_student, setSelectedStudent] = useState<any>();
  const [selected_instructor, setSelectedInstructor] = useState<any>();


  const [loading, setLoading] = useState<boolean>(false);


  const onSubmit = async () => {
    if (!state || !state.value || !selected_student.value || !finalState.description || !finalState.totalAmount ||
      !finalState.transactionId || !selectedDate || !selected_instructor.value
      ) {
      toast.error("All Fields are required!");
      return false
    }
    setLoading(true)
    const inputPaymentData: any = {
      studentId: selected_student.value,
      instructorId: selected_instructor.value,
      description: finalState.description,
      totalAmount: Number(finalState.totalAmount),
      transactionId: finalState.transactionId,
      state: state.value,
      invoice_date: moment(selectedDate).toISOString(),
    };




    await AdminService.createPartialInvoice(inputPaymentData)
      .then((res) => {
        if (res.status === 200) {
          props.setShow(false);
          setFinalState({
            description: "",
            totalAmount: 0,
            transactionId: "",
          })
          setState({})
          setSelectedStudent({})
          setSelectedInstructor({})

          toast.success("Success: Invoice Created!");
        }
      })
      .then((e) => {
        setLoading(false)
        console.error(e);
      });

    setLoading(false)

  };



  const handleChange = (e: any) => {
    setFinalState({ ...finalState, [e.target.name]: e.target.value })
  }


  const getStudents = async () => {
    await StudentService.getStudentsForSelect().then(res => {
      if(res.status == 200){
        setStudents(res.data.map((data:any) => {
            return {
              value: data._id,
              label: data.firstName + " " + data.lastName + " - " + data.email
            }
        }))
      }
    })
  }

  const getInst = async () => {
    await InstructorService.getInstForSelect().then(res => {
      if(res.status == 200){
        setInstructors(res.data.map((data:any) => {
            return {
              value: data._id,
              label: data.firstName + " " + data.lastName + " - " + data.email
            }
        }))
      }
    })
  }

  useEffect(() => {
    getInst()
    getStudents()
  },[])
  


  return (
    <SideModal
      title={"Create Partial Invoice"}
      show={props.show}
      handleClose={() => props.setShow(false)}
      body={
        <>
          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Student
            </Form.Label>
            <Select
              options={students}
              value={selected_student}
              isSearchable={true}
              placeholder="Select Student"
              onChange={(e: any) => {
                setSelectedStudent(e);
              }}
              isClearable
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Select Instructor
            </Form.Label>
            <Select
              options={instructors}
              value={selected_instructor}
              isSearchable={true}
              placeholder="Select Instructor"
              onChange={(e: any) => {
                setSelectedInstructor(e);
              }}
              isClearable
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Description
            </Form.Label>
            <Form.Control
              type="text"
              name="description"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Total Amount
            </Form.Label>
            <Form.Control
              type="number"
              name="totalAmount"
              onChange={handleChange}
            />
          </Form.Group>


          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              Transaction Id
            </Form.Label>
            <Form.Control
              type="text"
              name="transactionId"
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group className="mt-2 ">
            <Form.Label className="fw-bold text-secondary fs-12">
              State(Mandatory)
            </Form.Label>
            <Select
              options={states.map((state) => {
                return {
                  label: state,
                  value: state,
                };
              })}
              value={state}
              onChange={setState}
            />
          </Form.Group>

          <Form.Group className="mt-2">
            <Form.Label className="fw-bold text-secondary fs-12">
              Invoice Date
            </Form.Label>
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              className="form-control"
              dateFormat="d-MM-yyyy"
            />
          </Form.Group>

          <div
            className="bg-muted rounded-3 mt-3 p-3 fs-12"
            style={{ backgroundColor: "#f1f1f1" }}
          >
            <div className="fw-bold text-secondary">
              <div>
                {" "}
                Description:{" "}
                <span className="text-success">
                  {" "}
                   {finalState.description}
                </span>
              </div>
              <div>
                {" "}
                Total Amount (Without GST):{" "}
                <span className="text-info">
                  {" "}
                  {finalState.totalAmount}
                </span>
              </div>
              <div>
                {" "}
                Transaction Id:{" "}
                <span className="text-black">
                  {" "}
                  {finalState.transactionId}
                </span>
              </div>

              <div>
                {state.label == "Haryana" ? (
                  <>
                    <div>----------- GST -----------</div>
                    <div>
                      CGST:{" "}
                      <span className="text-success">
                        ₹{finalState.totalAmount * 0.09}
                      </span>
                    </div>
                    <div>
                      SGST:{" "}
                      <span className="text-success">
                        ₹{finalState.totalAmount * 0.09}
                      </span>
                    </div>
                    <div>
                      Total GST:{" "}
                      <span className="text-success">
                        ₹
                        {finalState.totalAmount  * 0.09 +
                          finalState.totalAmount * 0.09}
                      </span>
                      <div>----------------------------</div>
                    </div>
                  </>
                ) : (
                  <>
                    GST:{" "}
                    
                    <span className="text-success">
                      {" "}
                      {state.label && finalState.totalAmount > 0 ? (
                        <>+{finalState.totalAmount * 0.18}</>
                      ) : (
                        " --- All Fields Are Required --- "
                      )}
                    </span>
                  </>
                )}{" "}
              </div>
              <div>
                {" "}
                Total Price with GST:{" "}
                <span className="text-primary">
                  {" "}
                  {Number(finalState.totalAmount) > 0
                    ? Number(finalState.totalAmount) + Number(finalState.totalAmount * 0.18)
                    : " --- All Fields Are Required --- "}
                </span>
              </div>
            </div>
          </div>

          <Button
            className="mt-4"
            onClick={onSubmit}
            disabled={loading}
            variant="danger"
          >
            Create Partial GST Invoice <FaExclamationTriangle />
          </Button>
        </>
      }
    />
  );
}
