import React, { useEffect, useState } from "react";
import SideModal from "../SideModal/SideModal";
import ModalText from "../Text/Modal.text";
import { AdminService } from "../../services/admin.service";
import { Badge, Button, Dropdown, Form } from "react-bootstrap";
import { IoCopy, IoTrashBinSharp } from "react-icons/io5";
import toast from "react-hot-toast";
import ConfirmationModal from "./Confirmation.modal";
import ConfirmationGlobalModal from "./Confirmation.global.modal";
import { decimalValue, inclustiveGSTCalculation } from "../../store/reducers/auth/helper";

interface IPaymentLinkDetail {
  paymentId: boolean;
  onHide: any;
}

export default function PaymentLinkDetailModal(props: IPaymentLinkDetail) {
  const [plDetail, setPlDetail] = useState<any>(undefined);
  const [showConfirm, setShowConfirm] = useState(false);
  const [course, setCourse] = useState<any>(undefined);

  const [classes, setClasses] = useState(0);
  const [extraClasses, setExtraClasses] = useState(0);

  const getPaymentLinkDetail = async () => {
    await AdminService.getPaymentLink(props.paymentId)
      .then((res) => {
        setPlDetail(res.data.details);
        setCourse(res.data.course);
      })
      .catch((e) => {
        console.log(e);
      });
  };


  const markCompleted = async () => {
    if(!props.paymentId) return;
    await AdminService.markPaymentLinkCompleted(props.paymentId, Number(classes), Number(extraClasses)).then((res) => {
      if (res.status === 200) {
        toast.success("Marked as Completed");
        props.onHide()
      }
    }).catch(e => {
      toast.error("Something went wrong");
      toast.error(e.response.data);
    });
  }

  useEffect(() => {
    if (!props.paymentId) return;
    getPaymentLinkDetail();
  }, [props.paymentId]);

  if (!plDetail) {
    return <></>;
  }

  const amountReqToComplete = () => {
    if (!plDetail) return 0;
    return Number((calculatePPC() * Number(classes)).toFixed(2));
  }

  const calculateValueByCountry = (value: number) => {
    if(plDetail?.country === 'India'){
      return Number(inclustiveGSTCalculation(value));
    }else{
      return value
    }
  }

  const amountShort = () => {
    if (!plDetail) return 0;
    const totalRequired = Number((calculatePPC() * Number(classes)).toFixed(2));
    return Math.max(totalRequired - Number(calculateValueByCountry(Number(plDetail.partPaymentAmountRecieved)).toFixed(2)), 0);
  }

  const platformFee = () => {
    if (!plDetail) return 0;
    const totalRequired = Number((calculatePPC() * Number(classes)).toFixed(2));
    return Math.max((Number(calculateValueByCountry(Number(plDetail.partPaymentAmountRecieved)).toFixed(2)) - totalRequired), 0);
  }


  const calculatePPC = () => {
    if(!course) return 0;
    const sorted_discounts_by_classes = course.discount.sort((a: any, b: any) => a.num_classes - b.num_classes)
    let discount = 0
    for(let dis of sorted_discounts_by_classes){
      if(Number(dis.num_classes) <= classes){
        discount = dis.discount
      }
    }

    let ppc = course.price_per_class;
    if(plDetail?.country != 'India'){
      ppc = course.price_per_class + (course.price_per_class * 0.18)
    }
    return ppc - (ppc * discount / 100)
  }

  return (
    <>
    <SideModal
      title={"Payment Link Detail"}
      show={props.paymentId ? true : false}
      handleClose={props.onHide}
      body={
        <>
          <ModalText label="Payment Link ID" value={plDetail?._id} />
          <ModalText label="Student Name" value={plDetail?.studentId?.firstName + " " + plDetail?.studentId?.lastName} />
          <ModalText label="Country" value={plDetail?.country} />
          <ModalText label="Total Session" value={plDetail?.totalSession} />
          <ModalText label="Extra Classes" value={plDetail?.extraClasses} />
          <div className="d-flex flex-row">
            <ModalText label="Status" value={""} />
            {plDetail?.paymentStatus === "pending" ? (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="danger" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            ) : plDetail?.paymentStatus === "partial-paid" ? (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="warning" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            ) : (
              <td>
                <u className="fst-italic text-primary fs-12 cursor-pointer">
                  <Badge bg="success" className="me-1">
                    {plDetail?.paymentStatus?.toUpperCase()}{" "}
                  </Badge>
                </u>
              </td>
            )}
          </div>

          {plDetail?.paymentDetails && (
            <div className="d-flex flex-row align-items-center gap-2">
              <ModalText
                label="URL"
                value={plDetail?.paymentDetails?.short_url}
              />
              <div className="d-flex flex-row gap-1">
                {plDetail?.paymentDetails?.short_url && (
                  <Dropdown.Item
                    className="text-secondary fs-12 align-items-center d-flex fw-bold"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        plDetail?.paymentDetails?.short_url
                      );
                      toast.success("Link Copied");
                    }}
                  >
                    <IoCopy className="text-secondary me-1" />
                  </Dropdown.Item>
                )}

               
              </div>
            </div>
          )}

          <div className="card mt-5">
            <div className="card-header text-secondary">Payment Details</div>
            <ul className="list-group list-group-flush p-2">
              <ModalText
                label="Amount"
                value={"Rs. " + plDetail?.paymentDetails?.amount / 100}
              />
              <ModalText
                 label="Part Paid (if any)"
                value={
                  plDetail?.paymentPartialObject?.payload?.payment?.entity?.amount 
                    ? "Rs. " +  plDetail?.paymentPartialObject?.payload?.payment?.entity?.amount / 100
                    : "Rs. 0"
                }
              />
            </ul>
          </div>
          <hr/>
          {plDetail?.paymentStatus != 'completed' &&
          <div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="fw-bold">Dropped Partially paid Lead?</Form.Label>
              <Form.Text className="text-muted">
                Mark this as completed if the student has dropped the partially paid lead.
              </Form.Text>
              <div>
                <span>Total Paid Amount: </span>
                <span className=" fw-bold text-success">Rs. {plDetail?.partPaymentAmountRecieved || 0}</span>
              </div>

              <div>
                <span>Total Paid Amount(Excl. GST): </span>
                <span className=" fw-bold text-success">Rs. {calculateValueByCountry(Number(plDetail?.partPaymentAmountRecieved) || 0).toFixed(2)}</span>
              </div>
              <div>
              <span>PPC: </span>
              <span className="fw-bold text-secondary">Rs. {calculatePPC()}</span>
            </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Text className="text-muted">
                Total Classes to be given
              </Form.Text>
              <Form.Control type="number" value={classes} onChange={(e:any) => setClasses(e.target.value)} placeholder="Enter Total number Classes to be given"/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Text className="text-muted">
                Extra (FREE) Classes
              </Form.Text>
              <Form.Control type="number" value={extraClasses} onChange={(e:any) => setExtraClasses(e.target.value)} placeholder="Extra Classes"/>
            </Form.Group>


            <div>
              <span>Amount Required To Mark Completed: </span>
              <span className="text-primary fw-bold">Rs. {decimalValue(amountReqToComplete())}</span>
            </div>

            <div>
              <span>Amount Short: </span>
              <span className="text-danger fw-bold">Rs. {decimalValue(amountShort())}</span>
            </div>

            {plDetail?.country === 'India' &&
            <div>
              <span>Amount Short(with GST): </span>
              <span className="text-danger fw-bold">Rs. {decimalValue(amountShort() + (amountShort() *0.18))}</span>
            </div>
            }
            <div>
              <span>Platform Fee: </span>
              <span className="text-success fw-bold">Rs. {platformFee().toFixed(2)}</span>
            </div>

           

            <Button
                className="btn btn-primary mt-3"
                onClick={() => {
                 setShowConfirm(true);
                }} 
                disabled={decimalValue(amountShort() + (amountShort() *0.18)) > 0}
                >
                Mark as Completed (Partial Paid - RZP)
              </Button>
          </div>
        }
        </>
      }
    />
    <ConfirmationGlobalModal
      show={showConfirm}
      setShow={setShowConfirm}
      onSuccess={() => { 
        markCompleted() 
        setShowConfirm(false);
      }
      }
      alertBody={"Are you sure you want to mark this as completed?"}
      body={<>
        This will mark the payment link as completed, an invoice of partially paid enrollment would be generated and the student wouldnot be able to make a payment <b>(Only Supported on: Razorpay - Partially Paid Links)</b>
        </>
      }
    />
    </>
  );
}
