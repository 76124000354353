export const part_pay_items = [
    {
        value: 1000,
        label: "1000"
    }, {
        value: 2000,
        label: "2000"
    }, {
        value: 3000,
        label: "3000"
    }, {
        value: 4000,
        label: "4000"
    }, {
        value: 5000,
        label: "5000"
    }, {
        value: 6000,
        label: "6000"
    },
    {
        value: 7000,
        label: "7000"
    }, {
        value: 8000,
        label: "8000"
    }, {
        value: 9000,
        label: "9000"
    }, {
        value: 10000,
        label: "10000"
    }, {
        value: 11000,
        label: "11000"
    }, {
        value: 12000,
        label: "12000"
    }, {
        value: 13000,
        label: "13000"
    }, {
        value: 14000,
        label: "14000"
    }, {
        value: 15000,
        label: "15000"
    }
]