import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Form, Row, Table, Col } from "react-bootstrap";
import toast from "react-hot-toast";
import { BiDotsVertical } from "react-icons/bi";
import { useNavigate } from "react-router";
import CustomToggle from "../../../../../components/Menu/CustomMenu";
import { FontBold, NormalText } from "../../../../../components/Text/TableText.formats";
import { AdminService } from "../../../../../services/admin.service";
import { IoPencil, IoPencilOutline, IoVideocam } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import EditEnrollment from "../../../../../components/Modals/EditEnrollment.modal";
import ViewEnrollmentModal from "../../../../../components/Modals/ViewEnrollment.modal";

export default function StudentEnrollmentTable(props: any) {
  const id = props.id;

  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState<any>(undefined);
  const [showEnrollmentModal, setShowEnrollmentModal] = useState<any>(undefined)

  const navigate: any = useNavigate();


  const fetchStudentEnrollment = async () => {
    await AdminService.getStudentEnrollment({ id })
      .then((res) => {
        if (res.status === 200) {
          setTableData(res?.data);
          console.log(res?.data, "table data");
        }
      })
      .catch((err) => {
        toast.error("Unable to fetch Student Enrollment");
      });
  };

  useEffect(() => {
    fetchStudentEnrollment();
  }, [id, showModal]);


  return (
    <div>
      <Table striped borderless hover responsive size="sm">
        <thead>
          <tr className="dhun-table-header text-secondary">
            <th>#</th>
            <th>Id</th>
            <th>Teacher Name</th>
            <th>Instrument</th>
            <th colSpan={6} className="text-center">Sessions</th>
            <th>
              PPC Last Payment
            </th>
            <th></th>
          </tr>
          <tr>
            <td colSpan={4}></td>
            <td className="fs-10">Tot.</td>
            <td className="fs-10">Req.</td>
            <td className="fs-10">Sch.</td>
            <td className="fs-10">Left</td>
            <td className="fs-10">Late</td>
            <td className="fs-10">Can.</td>
          </tr>

        </thead>
        <tbody>
          {tableData && tableData?.length > 0
            ? tableData?.map((data: any, index: any) => {
              const last_payment = data?.payment_details?.length > 0 ? data?.payment_details[data?.payment_details?.length - 1] : null;
              return (
                <tr key={data?._id}>
                  <td>
                    <NormalText value={index + 1} />
                  </td>
                  <td>
                    <FontBold value={data.enrollmentId} />
                    <span className="fs-10 ms-2">
                      {data.type === "demo" && <Badge bg="secondary">Demo</Badge>}
                    </span>

                  </td>

                  <td onClick={() => { navigate("/admin/search/instructor/detail/" + data.instructor._id) }}>
                    <u
                      className="fst-italic text-primary fs-12 fs-underline cursor-pointer"
                    >{
                        data?.instructor?._id
                          ? data?.instructor?.firstName +
                          " " +
                          data?.instructor?.lastName
                          : "N.A."
                      }
                    </u>
                  </td>
                  <td>
                    <span className="fw-bold fs-12">
                      {data?.course?.instrument.toUpperCase()}
                    </span>
                  </td>

                  <td>
                    <span className="font-monospace fs-12">{data.num_classes || "---"}</span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12">{data.classes_requested || "---"}</span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12">{data.classes_scheduled || "---"}</span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12">{data.classes_left || "---"}</span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12">{data.classes_late_charged || "---"}</span>
                  </td>
                  <td>
                    <span className="font-monospace fs-12">{data.classes_canceled || "---"}</span>
                  </td>

                  <td>
                    <NormalText value={last_payment?.price ? "INR " + last_payment?.price.toFixed(2) : 0} />
                  </td>

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <BiDotsVertical />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setShowModal(data._id);
                          }}
                        >
                          {data.type == "paid" && <><IoPencilOutline className="text-primary me-1" /> Edit Enrollment</>}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="text-secondary fs-12 align-items-center d-flex fw-bold"
                          onClick={() => {
                            setShowEnrollmentModal(data._id);
                          }}
                        >
                          <><FaEye className="text-primary me-1" /> View Enrollment</>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </td>
                </tr>
              );
            })
            : "No Data Available"}
        </tbody>
      </Table>

      <EditEnrollment
        enrollmentId={showModal}
        setEnrollmentId={setShowModal}
      />

      <ViewEnrollmentModal
        show={showEnrollmentModal}
        handleClose={setShowEnrollmentModal}
      />
    </div>
  )
};
